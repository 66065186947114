<template>
  <div v-if="isshowgrid">
    <va-card title="User List">
      <div class="row">
        <div class="flex md6">
          <va-input
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex md6 text-right">
          <va-button data-toggle="tooltip" title="Click to start upgrade..." v-if="!upgradeInProgress" :disabled="disableUpgrade" icon="fa fa-refresh" @click="upgradeApps">Upgrade</va-button>
          <va-button data-toggle="tooltip" title="Click to start upgrade..." v-if="upgradeInProgress" :disabled="disableUpgrade" icon="fa fa-refresh fa-spin" @click="upgradeApps">Upgrade</va-button>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        style="max-height: 90vh;">
        <template slot="status" slot-scope="props">
          <va-button data-toggle="tooltip" title="Build Failed!" v-if="props.rowData.status === 'errored'" flat medium color="red" icon="fa fa-exclamation-circle" class="ma-0"></va-button>
          <va-button data-toggle="tooltip" title="Build Success!" v-if="props.rowData.status === 'finished'" flat medium color="green" icon="fa fa-check-circle-o" class="ma-0"></va-button>
          <va-button data-toggle="tooltip" title="Build in progress!" v-if="props.rowData.status === 'progress'" flat medium color="blue" icon="fa fa-spinner fa-pulse" class="ma-0"></va-button>
        </template>
        <!-- <template slot="actions" slot-scope="props">
					<va-button :disabled="props.rowData.status !== 'progress'" flat small color="gray" icon="fa fa-pencil" @click="viewApp(props.rowData)" class="ma-0"></va-button>
				</template> -->

      </va-data-table>
    </va-card>
  </div>
</template>

<script>

import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../i18n/search.js'
import config from '../i18n/en.json'
import VueSimpleAlert from 'vue-simple-alert'
Vue.use(VueSimpleAlert)
Vue.use(vueResource)

export default {
  name: 'operator',
  beforeCreate () {
    this.$http.get(config.menu.host + 'api/ott_app').then(response => {
      this.operators = (response.body.length > 0) ? response.body : []
      this.isshowForm = false
      this.isshowgrid = true
      this.getAllApps()
    }, err => {
      console.log('ERROR', err)
    })
  },
  data () {
    return {
      term: null,
      perPage: '6',
      perPageOptions: ['4', '6', '10', '20'],
      operators: [],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
    }
  },
  computed: {
    isOperatorsAvailable () {
      return Boolean(this.operators.length)
    },
    upgradeInProgress () {
      return this.operators.some(data => {
        return data.status === 'progress'
      })
    },
    disableUpgrade () {
      return !this.isOperatorsAvailable || this.upgradeInProgress
    },
    fields () {
      return [{
        name: '__slot:marker',
        dataClass: 'text-center',
      },
      {
        name: 'app_name',
        title: 'App Name',
      },
      {
        name: 'package_name',
        title: 'Package Name',
      },
      {
        name: '__slot:status',
        dataClass: 'text-left',
        title: 'App Status',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      return search(this.term, this.operators)
    },
  },
  methods: {
    getAllApps () {
      this.$http.get(config.menu.host + 'api/ott_app').then(response => {
        this.operators = (response.body.length > 0) ? response.body : []
        this.isshowgrid = true
        this.getAllAppsInterval()
      }, err => {
        console.log('ERROR', err)
      })
    },
    getAllAppsInterval () {
      this.interval = setInterval(function () {
        this.$http.get(config.menu.host + 'api/ott_app').then(response => {
          this.operators = (response.body.length > 0) ? response.body : []
          this.isshowgrid = true
        }, err => {
          console.log('ERROR', err)
        })
      }.bind(this), 10000)
    },
    upgradeApps () {
      this.$fire({
        text: 'Are you sure to upgrade app for all operators?',
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        timer: 10000,
      }).then(res => {
        if (res.value) {
          this.updateApps()
        }
      })
    },
    updateApps () {
      this.$http.get(config.menu.host + 'api/ott_app/upgrade').then(responseData => {
        Vue.notify({
          text: responseData.body,
          type: 'success',
        })
        this.list()
      }, err => {
        console.log('err,', err)
        if (errResponse && errResponse.body) {
          Vue.notify({
            text: errResponse.body,
            type: 'error',
          })
        }
      })
    },
    list () {
      this.getAllApps()
      this.isshowForm = false
      this.isshowgrid = true
    },
    search: function (term) {
      this.term = term
    },
  },
}
</script>

<style lang="scss">
</style>
